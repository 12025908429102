// eslint-disable-next-line no-unused-vars
import { Link } from "gatsby"
import PropTypes from "prop-types"
// eslint-disable-next-line no-unused-vars
import React, { useRef, useState } from "react"
import Img from "gatsby-image"
import { Row } from "react-bootstrap"
const Accordian = ({ children, ...propTypes }) => {
  const data = propTypes
  const laf = useRef()
  const smLaf = useRef()
  const changeShow = () => {
    laf.current.classList.toggle("show")
  }
  const smChangeShow = () => {
    smLaf.current.classList.toggle("show")
  }
  return (
    <div>
      <Row ref={laf} className="align-center laf-head py-5 d-none d-md-flex ">
        <div className="col-1 px-0">
          <Img fluid={JSON.parse(data.image)} />
        </div>
        <div className="col-3">
          <p className="wn-title mb-0 pl-3">{data.title}</p>
        </div>
        <div className="col-6" onClick={changeShow}>
          <p className={`fi-txt laf-txt`}>{children}</p>
        </div>
        <div className="col-2 text-right">
          <p className="acc-plus-icon" onClick={changeShow}>
            +
          </p>
          <p className="acc-minus-icon" onClick={changeShow}>
            -
          </p>
        </div>
      </Row>
      <div ref={smLaf} className="d-block d-md-none py-3 smlaf">
        <div className="d-flex">
          <Img fluid={JSON.parse(data.image)} className="laf-sm-icon" />
          <div className="laf-header ml-3" onClick={smChangeShow}>
            <div>
              <p className="wn-title my-0">{data.title}</p>
            </div>
            <div>
              <p className="acc-plus-icon mb-0">
                +
              </p>
              <p className="acc-minus-icon mb-0">
                -
              </p>
            </div>
          </div>
        </div>
        <div className="laf-bod">
          <p className="fi-txt mb-0">{children}</p>
        </div>
      </div>
    </div>
  )
}

Accordian.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Accordian.defaultProps = {
  image: ``,
  title: ``,
  content: ``,
}

export default Accordian
