import React, { useRef, useContext, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Accordian from "../components/accordian"
import {
  Container,
  Card,
  Row,
  Accordion,
  useAccordionToggle,
  AccordionContext,
} from "react-bootstrap"
import Slider from "react-slick"
import india from "../images/india.png"
import pimg1 from "../images/lifeAsAFellow/whoAndWhatDoTheyTeach.png"
import pimg2 from "../images/lifeAsAFellow/whatSalaryDoTheyGet.png"
import pimg3 from "../images/lifeAsAFellow/8WeekProgram.png"
import pimg4 from "../images/lifeAsAFellow/1stYear.png"
import pimg5 from "../images/lifeAsAFellow/2ndYear.png"
import soc1 from "../images/lifeAsAFellow/soc1.png"
import soc2 from "../images/lifeAsAFellow/soc2.png"
import soc3 from "../images/lifeAsAFellow/soc3.png"
import soc4 from "../images/lifeAsAFellow/soc4.png"
import soc5 from "../images/lifeAsAFellow/soc5.png"
import logo1 from "../images/fellows/logo1.jpg"
import logo2 from "../images/fellows/logo2.jpg"
import left from "../images/left.svg"
import right from "../images/right.svg"
import AOS from "aos"
import "aos/dist/aos.css"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import cag1 from "../images/client_logos/cag1.png"
import cag2 from "../images/client_logos/cag2.png"
import cag3 from "../images/client_logos/cag3.png"
import cag4 from "../images/client_logos/cag4.png"
import cag5 from "../images/client_logos/cag5.png"
import cag6 from "../images/client_logos/cag6.png"
import cag7 from "../images/client_logos/cag7.png"
import cag8 from "../images/client_logos/cag8.png"
import cag9 from "../images/client_logos/cag9.png"
import cag10 from "../images/client_logos/cag10.png"

import ofcar1 from "../images/fellows/ofcar1.jpg"
import ofcar2 from "../images/fellows/ofcar2.jpg"
import ofcar3 from "../images/fellows/ofcar3.jpg"
import ofcar4 from "../images/fellows/ofcar4.jpg"
import ofcar5 from "../images/fellows/ofcar5.jpg"
import ofcar6 from "../images/fellows/ofcar6.jpg"
import ofcar7 from "../images/fellows/ofcar7.jpg"
import ofcar8 from "../images/fellows/ofcar8.jpg"
import ofcar9 from "../images/fellows/ofcar9.jpg"
import ofcar10 from "../images/fellows/ofcar10.jpg"
import ofcar11 from "../images/fellows/ofcar11.jpg"
import ofcar12 from "../images/fellows/ofcar12.jpg"

// eslint-disable-next-line react/prop-types
function ContextAwareToggle({ children, eventKey, callback }) {
  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])

  const currentEventKey = useContext(AccordionContext)

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = currentEventKey === eventKey
  return (
    <div
      type="button"
      className={isCurrentEventKey ? "show" : ""}
      onClick={decoratedOnClick}
    >
      {children}
    </div>
  )
}

const LifeAsAFellow = () => {
  const acc = {
    connect: {
      title: "Connect",
    },
    envision: {
      title: "Envision",
    },
    plan: {
      title: "Plan",
    },
    execute: {
      title: "Execute",
    },
    reflect: {
      title: "Reflect",
    },
  }
  const data = useStaticQuery(graphql`
    query {
      conimg: file(relativePath: { eq: "connect.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      envimg: file(relativePath: { eq: "envision.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      planimg: file(relativePath: { eq: "plan.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      exeimg: file(relativePath: { eq: "execute.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      refimg: file(relativePath: { eq: "reflect.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const lafCar = useRef()
  const soc = useRef()
  const settingsLafCar = {
    className: "center",
    centerMode: true,
    dots: false,
    centerPadding: "60px",
    arrows: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  const settingsSoc = {
    dots: true,
    dotsClass: "slick-dots fel-indi laf-fel-indi",
    infinite: true,
    fade: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          fade: false,
        },
      },
    ],
  }
  const impactPrevious = () => {
    lafCar.current.slickPrev()
  }
  const impactNext = () => {
    lafCar.current.slickNext()
  }
  const socPrevious = () => {
    soc.current.slickPrev()
  }
  const socNext = () => {
    soc.current.slickNext()
  }
  return (
    <Layout pageName="Life As A Fellow">
      <SEO title="Life As A Fellow" />
      <div className="bg-sec py-lg-5">
        <iframe
          width="100%"
          id="youtubeplayermob"
          className="d-block d-lg-none"
          frameBorder="0"
          src="https://www.youtube-nocookie.com/embed/w8aof8MkqFw"
          title="Teach For India"
          data-aos="fade"
          data-aos-delay="100"
          data-aos-duration="1000"
        ></iframe>
        <Container className="py-4 py-lg-0 mb-lg-5">
          <div className="">
            <p
              className="ed-title text-center text-lg-left"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              An opportunity to drive impact
            </p>
            <Row className="mb-lg-4 ">
              <div className="col-lg-6 text-left">
                <p
                  className="jtf-head text-center text-lg-left"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                >
                  The journey of a Fellow
                </p>
              </div>
              <div className="col-lg-5 offset-lg-1 text-left text-lg-right">
                <p
                  className="gentxt"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                >
                  India&apos;s children need courageous leaders, ones who will
                  work at the school and systems level to bring about long
                  lasting change. The kind who will put their students first and
                  work towards an India free of poverty and filled with love.
                </p>
              </div>
            </Row>
          </div>
          <iframe
            width="100%"
            id="youtubeplayer"
            className="d-none d-lg-block"
            frameBorder="0"
            src="https://www.youtube-nocookie.com/embed/w8aof8MkqFw"
            title="Teach For India"
            data-aos="fade"
            data-aos-delay="100"
            data-aos-duration="1000"
          ></iframe>
        </Container>
      </div>
      <Container className="py-lg-5 d-none d-lg-block">
        <div className="text-center">
          <p
            className="ed-title mb-lg-4"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            Who are they?
          </p>
          <p
            className="fi-head max-50 mx-auto"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            Our Fellows are critical thinkers and problem solvers
          </p>
        </div>
      </Container>
      <div
        className="pt-4 pb-5 py-lg-5 posrel"
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-duration="1000"
      >
        <Slider {...settingsLafCar} ref={lafCar} className="laf-car">
          <img src={ofcar1} className="h200" />
          <img src={ofcar2} className="h200" />
          <img src={ofcar3} className="h200" />
          <img src={ofcar4} className="h200" />
          <img src={ofcar5} className="h200" />
          <img src={ofcar6} className="h200" />
          <img src={ofcar7} className="h200" />
          <img src={ofcar8} className="h200" />
          <img src={ofcar9} className="h200" />
          <img src={ofcar10} className="h200" />
          <img src={ofcar11} className="h200" />
          <img src={ofcar12} className="h200" />
        </Slider>
        <div className="laf-gal-indi car-indi">
          <div className="left" onClick={impactPrevious}>
            <img src={left} />
          </div>
          <div className="right" onClick={impactNext}>
            <img src={right} />
          </div>
        </div>
      </div>
      <Container className="py-lg-5 d-block d-lg-none">
        <div className="text-center">
          <p
            className="ed-title mb-lg-4"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            Who are they?
          </p>
          <p
            className="fi-head "
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            Our Fellows are critical thinkers and problem solvers
          </p>
        </div>
      </Container>
      <Container className="py-lg-5">
        <p
          className="fi-txt max-50 mx-auto max-sm-100 text-left text-lg-center pb-5"
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          They have grit, courage and set high expectations for themselves and
          for others and demonstrate excellent interpersonal skills through
          their interactions with various stakeholders. At the core of
          everything they do, they hold the 8Cs close to their hearts -
          Curiosity, Consciousness, Creativity, Critical Thinking,
          Communication, Collaboration, Compassion and Courage. Here are some of
          the institutions and organisations that have given us our leaders over
          the years.
        </p>
        <div
          className="col-lg-10 offset-lg-1 my-3 my-lg-5 max-sm-60 mx-auto pb-5 pb-lg-0"
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <div className="client-logo-grid pb-lg-5">
            <Link to="/" className="client-logo-cont">
              <img src={cag1} className="cleint-logo w-100" />
            </Link>
            <Link to="/" className="client-logo-cont">
              <img src={cag2} className="cleint-logo w-100" />
            </Link>
            <Link to="/" className="client-logo-cont">
              <img src={cag3} className="cleint-logo w-100" />
            </Link>
            <Link to="/" className="client-logo-cont">
              <img src={cag4} className="cleint-logo w-100" />
            </Link>
            <Link to="/" className="client-logo-cont">
              <img src={cag5} className="cleint-logo w-100" />
            </Link>
            <Link to="/" className="client-logo-cont">
              <img src={cag6} className="cleint-logo w-100" />
            </Link>
            <Link to="/" className="client-logo-cont">
              <img src={cag7} className="cleint-logo w-100" />
            </Link>
            <Link to="/" className="client-logo-cont">
              <img src={cag8} className="cleint-logo w-100" />
            </Link>
            <Link to="/" className="client-logo-cont">
              <img src={cag9} className="cleint-logo w-100" />
            </Link>
            <Link to="/" className="client-logo-cont">
              <img src={cag10} className="cleint-logo w-100" />
            </Link>
          </div>
          <p className="ed-txt text-center d-none d-sm-block">And many more.</p>
        </div>
      </Container>

      <div className="ban-bg-black py-5">
        <Container>
          <Row>
            <div className="col-lg-4 pr-lg-0">
              <p
                className="ed-title white text-center text-lg-left"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1000"
              >
                Our Regions
              </p>
              <p
                className="jtf-head white max-sm-70 mx-auto mb-5 mb-lg-0 text-center text-lg-left"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1000"
              >
                Where do they teach?
              </p>
              <div className="d-block d-lg-none">
                <Accordion className="map-acc mb-5" defaultActiveKey="0">
                  <Card
                    data-aos="fade-up"
                    data-aos-delay="100"
                    data-aos-duration="1000"
                  >
                    <Card.Header>
                      <ContextAwareToggle eventKey="0">
                        <div className="eleg-title">
                          <p className="cdl cdl-ahmedabad mb-0">Ahmedabad</p>
                          <span className="acc-plus-icon">+</span>
                          <span className="acc-minus-icon">-</span>
                        </div>
                      </ContextAwareToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <p className="cd-title text-left">Year 2021</p>
                        <p className="cd-head text-left">
                          47 Fellows <br />
                          1800 Students <br />
                          180 Alumni
                        </p>
                        {/* <p className="uc-link text-right mb-0">Read More</p> */}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card
                    data-aos="fade-up"
                    data-aos-delay="100"
                    data-aos-duration="1000"
                  >
                    <Card.Header>
                      <ContextAwareToggle eventKey="1">
                        <div className="eleg-title">
                          <p className="cdl cdl-bengaluru mb-0">Bengaluru</p>
                          <span className="acc-plus-icon">+</span>
                          <span className="acc-minus-icon">-</span>
                        </div>
                      </ContextAwareToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <p className="cd-title text-left">Year 2021</p>
                        <p className="cd-head text-left">
                          120 Fellows
                          <br />
                          3,000 Students
                          <br />
                          222 Alumni260 Fellows
                        </p>
                        {/* <p className="uc-link text-right mb-0">Read More</p> */}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card
                    data-aos="fade-up"
                    data-aos-delay="100"
                    data-aos-duration="1000"
                  >
                    <Card.Header>
                      <ContextAwareToggle eventKey="2">
                        <div className="eleg-title">
                          <p className="cdl cdl-chennai mb-0">Chennai</p>
                          <span className="acc-plus-icon">+</span>
                          <span className="acc-minus-icon">-</span>
                        </div>
                      </ContextAwareToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body>
                        <p className="cd-title text-left">Year 2021</p>
                        <p className="cd-head text-left">
                          110 Fellows
                          <br />
                          3300 Students
                          <br />
                          365 Alumni
                        </p>
                        {/* <p className="uc-link text-right mb-0">Read More</p> */}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card
                    data-aos="fade-up"
                    data-aos-delay="100"
                    data-aos-duration="1000"
                  >
                    <Card.Header>
                      <ContextAwareToggle eventKey="3">
                        <div className="eleg-title">
                          <p className="cdl cdl-delhi mb-0">Delhi</p>
                          <span className="acc-plus-icon">+</span>
                          <span className="acc-minus-icon">-</span>
                        </div>
                      </ContextAwareToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body>
                        <p className="cd-title text-left">Year 2021</p>
                        <p className="cd-head text-left">
                          260 Fellows
                          <br />
                          8300 Students
                          <br />
                          952 Alumni
                        </p>
                        {/* <p className="uc-link text-right mb-0">Read More</p> */}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card
                    data-aos="fade-up"
                    data-aos-delay="100"
                    data-aos-duration="1000"
                  >
                    <Card.Header>
                      <ContextAwareToggle eventKey="4">
                        <div className="eleg-title">
                          <p className="cdl cdl-hyderabad mb-0">Hyderabad</p>
                          <span className="acc-plus-icon">+</span>
                          <span className="acc-minus-icon">-</span>
                        </div>
                      </ContextAwareToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="4">
                      <Card.Body>
                        <p className="cd-title text-left">Year 2021</p>
                        <p className="cd-head text-left">
                          87 Fellows
                          <br />
                          3600 Students
                          <br />
                          422 Alumni
                        </p>
                        {/* <p className="uc-link text-right mb-0">Read More</p> */}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card
                    data-aos="fade-up"
                    data-aos-delay="100"
                    data-aos-duration="1000"
                  >
                    <Card.Header>
                      <ContextAwareToggle eventKey="5">
                        <div className="eleg-title">
                          <p className="cdl cdl-kolkata mb-0">Kolkata</p>
                          <span className="acc-plus-icon">+</span>
                          <span className="acc-minus-icon">-</span>
                        </div>
                      </ContextAwareToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="5">
                      <Card.Body>
                        <p className="cd-title text-left">Year 2022</p>
                        <p className="cd-head text-left">Coming Soon!</p>
                        {/* <p className="uc-link text-right mb-0">Read More</p> */}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card
                    data-aos="fade-up"
                    data-aos-delay="100"
                    data-aos-duration="1000"
                  >
                    <Card.Header>
                      <ContextAwareToggle eventKey="6">
                        <div className="eleg-title">
                          <p className="cdl cdl-mumbai mb-0">Mumbai</p>

                          <span className="acc-plus-icon">+</span>
                          <span className="acc-minus-icon">-</span>
                        </div>
                      </ContextAwareToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="6">
                      <Card.Body>
                        <p className="cd-title text-left">Year 2021</p>
                        <p className="cd-head text-left">
                          144 Fellows
                          <br />
                          4000 Students
                          <br />
                          817 Alumni
                        </p>
                        {/* <p className="uc-link text-right mb-0">Read More</p> */}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card
                    data-aos="fade-up"
                    data-aos-delay="100"
                    data-aos-duration="1000"
                  >
                    <Card.Header>
                      <ContextAwareToggle eventKey="7">
                        <div className="eleg-title">
                          <p className="cdl cdl-pune mb-0">Pune</p>
                          <span className="acc-plus-icon">+</span>
                          <span className="acc-minus-icon">-</span>
                        </div>
                      </ContextAwareToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="7">
                      <Card.Body>
                        <p className="cd-title text-left">Year 2021</p>
                        <p className="cd-head text-left">
                          145 Fellows
                          <br />
                          7500 Students
                          <br />
                          854 Alumni
                        </p>
                        {/* <p className="uc-link text-right mb-0">Read More</p> */}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
              <div className="citylist pt-lg-5 d-none d-lg-block">
                <p
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                  className="cdl cdl-ahmedabad"
                >
                  Ahmedabad
                </p>
                <p
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                  className="cdl cdl-bengaluru"
                >
                  Bengaluru
                </p>
                <p
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                  className="cdl cdl-chennai"
                >
                  Chennai
                </p>
                <p
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                  className="cdl cdl-delhi"
                >
                  Delhi
                </p>
                <p
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                  className="cdl cdl-hyderabad"
                >
                  Hyderabad
                </p>
                <p
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                  className="cdl cdl-kolkata"
                >
                  Kolkata
                </p>
                <p
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                  className="cdl cdl-mumbai"
                >
                  Mumbai
                </p>
                <p
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                  className="cdl cdl-pune"
                >
                  Pune
                </p>
              </div>
            </div>
            <div
              className="col-lg-8 d-none d-lg-block"
              data-aos="fade"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              <div className="indiaMap">
                <img src={india} className="w-100" />
                <div className="city delhi">
                  <div className="circle"></div>
                  <div className="details text-center">
                    <div className="cd-cont">
                      <p className="cd-title">Year 2021</p>
                      <p className="cd-head">
                        260 Fellows
                        <br />
                        8300 Students
                        <br />
                        952 Alumni
                      </p>
                    </div>
                  </div>
                </div>
                <div className="city ahmedabad">
                  <div className="circle"></div>
                  <div className="details text-center">
                    <div className="cd-cont">
                      <p className="cd-title">Year 2021</p>
                      <p className="cd-head">
                        47 Fellows
                        <br />
                        1800 Students
                        <br />
                        180 Alumni
                      </p>
                    </div>
                  </div>
                </div>
                <div className="city bengaluru">
                  <div className="circle"></div>
                  <div className="details text-center">
                    <div className="cd-cont">
                      <p className="cd-title">Year 2021</p>
                      <p className="cd-head">
                        120 Fellows
                        <br />
                        3,000 Students
                        <br />
                        222 Alumni
                      </p>
                    </div>
                  </div>
                </div>
                <div className="city chennai">
                  <div className="circle"></div>
                  <div className="details text-center">
                    <div className="cd-cont">
                      <p className="cd-title">Year 2021</p>
                      <p className="cd-head">
                        110 Fellows
                        <br />
                        3300 Students
                        <br />
                        365 Alumni
                      </p>
                    </div>
                  </div>
                </div>
                <div className="city hyderabad">
                  <div className="circle"></div>
                  <div className="details text-center">
                    <div className="cd-cont">
                      <p className="cd-title">Year 2021</p>
                      <p className="cd-head">
                        87 Fellows
                        <br />
                        3600 Students
                        <br />
                        422 Alumni
                      </p>
                    </div>
                  </div>
                </div>
                <div className="city kolkata">
                  <div className="circle"></div>
                  <div className="details text-center">
                    <div className="cd-cont">
                      <p className="cd-title">Year 2022</p>
                      <p className="cd-head">Coming soon!</p>
                    </div>
                  </div>
                </div>
                <div className="city mumbai">
                  <div className="circle"></div>
                  <div className="details text-center">
                    <div className="cd-cont">
                      <p className="cd-title">Year 2021</p>
                      <p className="cd-head">
                        144 Fellows
                        <br />
                        4000 Students
                        <br />
                        817 Alumni
                      </p>
                    </div>
                  </div>
                </div>
                <div className="city pune">
                  <div className="circle"></div>
                  <div className="details text-center">
                    <div className="cd-cont">
                      <p className="cd-title">Year 2021</p>
                      <p className="cd-head">
                        145 Fellows
                        <br />
                        7500 Students
                        <br />
                        854 Alumni
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
          <Row className="py-lg-5 text-center text-lg-left">
            <div className="col-lg-6">
              <p
                className="fi-txt white"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1000"
              >
                Teach For India Fellows will be placed in low-income schools in
                one of our 8 cities, as per their choice - Ahmedabad, Bengaluru,
                Chennai, Delhi, Hyderabad, Kolkata, Mumbai and Pune. All Fellows
                will be required to reside in one of these cities during the
                period of their Fellowship.
              </p>
            </div>
            <div className="col-lg-6">
              <p
                className="fi-txt white"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1000"
              >
                If you are unable to do the Fellowship in one of our placement
                cities, please note that we plan to expand into more cities in
                the coming years.
              </p>
            </div>
          </Row>
          <Row className="d-none d-lg-flex">
            <div className="col-lg-6 text-right pr-0">
              <a
                href="https://apply.teachforindia.org/"
                target="_blank"
                rel="noreferrer noopener"
                className="butn butn-main"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1000"
              >
                APPLY ONLINE
              </a>
            </div>
            <div className="col-lg-6 pl-5 align-center">
              <p
                className="fi-txt white mb-0"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1000"
              >
                <AnchorLink
                  to="/how-to-join#application-process"
                  title="Selection process"
                  className="fi-txt white"
                  stripHash
                />
              </p>
            </div>
          </Row>
          <div
            className="text-center p-lg-5 py-5 "
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <p className="bintxt">
              While Teach For India works in 8 cities, our Alumni and
              entrepreneurs network indirectly impacts 33 million children
              across the nation.
            </p>
          </div>
          <Row className="d-flex d-lg-none">
            <div className="col-6 text-right pr-0">
              <a
                href="https://apply.teachforindia.org/"
                target="_blank"
                rel="noreferrer noopener"
                className="butn butn-spl butn-main"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1000"
              >
                APPLY ONLINE
              </a>
            </div>
            <div className="col-6 align-center">
              <p
                className="uc-link mb-0"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1000"
              >
                Eligibility Criteria
              </p>
            </div>
          </Row>
        </Container>
      </div>

      <div className="bg-sec py-5">
        <Container className="py-lg-5 inverted sm-med">
          <Row className="py-3 py-lg-5 whatsnew">
            <div
              className="col-lg-7 pr-5 wn-img"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              <img src={pimg1} className="w-100" />
            </div>
            <div
              className="col-lg-5 wn-cont text-center text-lg-left"
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-duration="1000"
            >
              <p className="wn-title">
                Who and what <br />
                do they teach?
              </p>
              <p className="fi-txt mb-0">
                <strong className="sm-unstrong superStrong">
                  Teach For India Fellows are placed in classrooms from the 1st
                  to 10th grade
                </strong>{" "}
                where they teach all subjects — including English, Mathematics,
                Social Studies, and Science with the exception of regional
                languages. Although knowing the students&apos; mother-tongue is
                helpful while interacting within their communities, it is not a
                necessary prerequisite as our schools are English medium.
              </p>
            </div>
          </Row>
          <Row className="py-3 py-lg-5 whatsnew">
            <div
              className="col-lg-7 pr-5 wn-img"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              <img src={pimg2} className="w-100" />
            </div>
            <div
              className="col-lg-5 wn-cont text-center text-lg-left"
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-duration="1000"
            >
              <p className="wn-title">
                What salary and
                <br />
                expenses do they get?
              </p>
              <p className="fi-txt mb-0">
                <strong className="sm-unstrong superStrong">
                  Teach For India Fellows are paid a salary of INR 20,400 per
                  month.
                </strong>{" "}
                If required to relocate from their home city, Fellows are given
                a housing allowance ranging from INR 5500 to INR 10,000,
                depending on the city in which they stay. In addition, Fellows
                are reimbursed for school supplies.
              </p>
            </div>
          </Row>
        </Container>
      </div>

      <Container className="py-5">
        <div className="max-75 max-sm-100 text-center mx-auto">
          <p
            className="ed-title"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            Your journey as a Fellow
          </p>
          <p
            className="wn-title mt-2 mb-3 my-lg-0"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            What do I learn from the Fellowship?
          </p>
        </div>
        <Row className="pb-5 py-lg-5">
          <div className="col-lg-6">
            <p
              className="fi-txt mb-0"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              Excellent teachers and leaders build environments that foster
              learning and growth. They connect deeply with themselves, their
              context, and the communities that they serve. They immerse
              themselves in the lived experiences of their people and together
              envision bold possibilities. They plan effectively to meet this
              vision. They build a culture that promotes safety and love
              alongside driving for outcomes.
              <span className="d-inline d-lg-none">
                They consistently reflect on their results through the
                leadership they display every day. The five pillars build our
                Fellows as leaders with a strong commitment to Self, Others and
                India. This sets them up to contribute in the most effective
                manner towards an India free of poverty, filled with love, and
                the “one day” that we envision for all children.
              </span>
            </p>
          </div>
          <div className="col-lg-6 d-lg-block d-none">
            <p
              className="fi-txt"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              They consistently reflect on their results through the leadership
              they display every day. The five pillars build our Fellows as
              leaders with a strong commitment to Self, Others and India. This
              sets them up to contribute in the most effective manner towards an
              India free of poverty, filled with love, and the “one day” that we
              envision for all children.
            </p>
          </div>
        </Row>
        <div
          className="laf-acc"
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <Accordian
            image={JSON.stringify(data.conimg.childImageSharp.fluid)}
            title={acc.connect.title}
          >
            When we connect, we establish a deep and evolving understanding of
            ourselves as individuals, of the communities we serve, and the
            systems we operate within. We approach those around us with
            <strong> compassion</strong>, ask questions with
            <strong> curiosity</strong>, and become <strong> conscious</strong>{" "}
            of our own beliefs, and understanding of the world.
          </Accordian>
          <Accordian
            image={JSON.stringify(data.envimg.childImageSharp.fluid)}
            title={acc.envision.title}
          >
            When we envision, we reimagine education for our children. We
            operate with <strong>curiosity</strong> to decode power structures
            and identify key barriers to transformation. We challenge the status
            quo with <strong>courage.</strong> We <strong>communicate</strong>{" "}
            with our people to envision an alternate future and we develop a
            <strong> consciousness</strong> of who we are called to be as
            leaders to best serve this vision.
          </Accordian>
          <Accordian
            image={JSON.stringify(data.planimg.childImageSharp.fluid)}
            title={acc.plan.title}
          >
            When we plan, we think of ways to best reach the outcomes we have
            envisioned. We leverage our <strong>critical thinking</strong>{" "}
            abilities to pick tasks that are aligned, well structured, and
            logically sequenced. We think of <strong>creative</strong> ways to
            bring joy, fun, and excitement and purpose to every space that we
            design and facilitate.
          </Accordian>
          <Accordian
            image={JSON.stringify(data.exeimg.childImageSharp.fluid)}
            title={acc.execute.title}
          >
            When we execute, we enable the creation of spaces filled with joy,
            safety and <strong>collaboration.</strong> We{" "}
            <strong>communicate</strong> clearly, listen intently and enable
            actions that will help our people reach the goals that have been
            set. We face challenges that arise with <strong>courage</strong>,
            knowing that they are an opportunity to grow.
          </Accordian>
          <Accordian
            image={JSON.stringify(data.refimg.childImageSharp.fluid)}
            title={acc.reflect.title}
          >
            When we reflect, we get <strong>curious</strong> about our
            leadership, who we are choosing to be in every moment and the
            results being created in our world as outcomes. We are{" "}
            <strong>conscious</strong> of our emotions and our states of being.
            We <strong>think critically</strong> about key levers that will
            shift us towards transformative outcomes.
          </Accordian>
        </div>
      </Container>
      <div className="bg-sec py-4 py-lg-5">
        <Container className="py-lg-5 inverted sm-med">
          <Row className="py-3 py-lg-5 whatsnew">
            <div
              className="col-lg-7 pr-5 wn-img"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              <img src={pimg3} className="w-100" />
            </div>
            <div
              className="col-lg-5 wn-cont text-center text-lg-left"
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-duration="1000"
            >
              <p className="wn-title">
                8-week blended residential training program
              </p>
              <p className="gentxt">
                From the moment of acceptance, Teach For India Fellows are
                invited to a blended training program. The Training Institute is
                also a place of growth and peer support- acquainting incoming
                Fellows with others on the same path, learning and providing
                help to one another in an atmosphere of love, patience, openness
                and development.
              </p>
            </div>
          </Row>
          <Row className="py-3 py-lg-5 whatsnew">
            <div
              className="col-lg-7 pr-5 wn-img"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              <img src={pimg4} className="w-100" />
            </div>
            <div
              className="col-lg-5 wn-cont text-center text-lg-left"
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-duration="1000"
            >
              <p className="wn-title">1st Year</p>
              <p className="fi-txt">
                Fellows refine their teaching skills and start establishing
                respect and trust among various stakeholders within the school
                community. They learn skills like planning purposefully, setting
                a vision and goals, building relationships and investing in
                others, executing effectively, constantly improving
                effectiveness, building a network of support and many more.
              </p>
            </div>
          </Row>
          <Row className="py-3 py-lg-5 whatsnew">
            <div
              className="col-lg-7 pr-5 wn-img"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              <img src={pimg5} className="w-100" />
            </div>
            <div
              className="col-lg-5 wn-cont text-center text-lg-left"
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-duration="1000"
            >
              <p className="wn-title">2nd Year </p>
              <p className="fi-txt">
                In the second year, Fellows achieve significant academic gains
                for Students and through their &ldquo;Be The Change
                Projects&rdquo; deepen their impact beyond classrooms.
                Furthermore, Teach For India invests in leaders who are willing
                to commit to long-term change and have the perseverance to work
                hard in challenging situations. In this context, the two-year
                commitment is a small step towards long-term reform.
              </p>
            </div>
          </Row>
          <div
            className="text-center d-none d-lg-block"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <a
              href="https://apply.teachforindia.org/"
              target="_blank"
              rel="noreferrer noopener"
              className="butn butn-main"
            >
              APPLY ONLINE
            </a>
          </div>
        </Container>
      </div>

      <Container>
        <div className="max-60 max-sm-100 py-lg-5 pt-5 text-center text-lg-left">
          <p
            className="ed-title "
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            Stories of Change
          </p>
          <p
            className="fi-head"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            Transformational stories from the classroom
          </p>
        </div>
        <Slider
          {...settingsSoc}
          className="laf-fel"
          ref={soc}
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <div className="fel-car pt-3 py-lg-5">
            <div className="fel-cont">
              <p className="fel-car-title">Nimita’s incredible journey</p>
              <p className="fel-car-txt">
                Nimita Raut joined the Fellowship after a successful career as
                an Engineering Professor for many years. Through her Fellowship
                experience, she aimed to improve Student outcomes by bettering
                teacher training in under-resourced schools. In October 2019,
                Nimita shared her incredible journey from a college classroom to
                a government school with the nation, when she participated in
                the popular game show- “Kaun Banega Crorepati” (Who Wants to be
                a Millionaire).
              </p>
              <div className="text-right text-lg-left w-100">
                <a
                  className="butn butn-main modelButn butn-spl"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://yourstory.com/2018/09/6-inspiring-tfi-fellows-social-impact"
                >
                  Read more
                </a>
              </div>
            </div>
            <div className="fel-img-cont">
              <img src={soc1} className="w-100" />
            </div>
          </div>
          <div className="fel-car pt-3 py-lg-5">
            <div className="fel-cont">
              <p className="fel-car-title">Accolades for Aravindhan</p>
              <p className="fel-car-txt">
                Coming from an Engineering background, Aravindhan knew he had to
                equip his class of 4th and 5th graders with an understanding of
                technology, to be ready for our rapidly changing world. He also
                knew that he’d have to work tirelessly to not only raise funds
                for this aspiration, but to specifically design curriculum and
                map his Student’s learning outcomes. And that’s exactly what he
                did.
              </p>
              <div className="text-right text-lg-left w-100">
                <a
                  className="butn butn-main modelButn butn-spl"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.edexlive.com/people/2019/feb/26/meet-aravindhan-anbazhagan-a-teach-for-india-fellow-who-stacked-up-awards-for-his-inspirational-wor-5402.html"
                >
                  Read more
                </a>
              </div>
            </div>
            <div className="fel-img-cont">
              <img src={soc2} className="w-100" />
            </div>
          </div>
          <div className="fel-car pt-3 py-lg-5">
            <div className="fel-cont">
              <p className="fel-car-title">
                Vivek&apos;s adventurous journey from education to Antarctica
              </p>
              <p className="fel-car-txt">
                The Fellowship made Vivek develop insights on what a quality
                education means. He also taught his children about a pressing
                crisis-climate change. During his Fellowship, he undertook his
                first expedition to Antarctica, under renowned Polar Explorer -
                Sir Robert Swan. Combining his learnings from the Fellowship,
                and this monumental trip; Vivek graduated from the Fellowship
                with a dream of owning his own educational enterprise, and
                that’s exactly what he did.
              </p>
              <div className="text-right text-lg-left w-100">
                <a
                  className="butn butn-main modelButn butn-spl"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://timesofindia.indiatimes.com/city/gurgaon/gurgaon-man-chosen-from-over-a-lakh-applicants-for-the-month-long-antarctic-sabbatical/articleshow/72023081.cms"
                >
                  Read more
                </a>
              </div>
            </div>
            <div className="fel-img-cont">
              <img src={soc3} className="w-100" />
            </div>
          </div>
          <div className="fel-car pt-3 py-lg-5">
            <div className="fel-cont">
              <p className="fel-car-title">Angeline’s ordinary miracles</p>
              <p className="fel-car-txt">
                Angeline’s path to the Fellowship was shaped by the fact that
                she came from a low-income community herself and so, was
                intimately aware of the problems that the lack of a quality
                education poses for children. As she entered her classroom, she
                was met with resistance from her Students, and had to face up to
                her own fears and apprehensions to be able to do what she always
                wanted to- create a better world for children in tough
                circumstances. Through tireless efforts, she was able to see the
                light at the end of the tunnel.
              </p>
              <div className="text-right text-lg-left w-100">
                <a
                  className="butn butn-main modelButn butn-spl"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.thebetterindia.com/29282/angie-teach-for-india/"
                >
                  Read more
                </a>
              </div>
            </div>
            <div className="fel-img-cont">
              <img src={soc4} className="w-100" />
            </div>
          </div>
          <div className="fel-car pt-3 py-lg-5">
            <div className="fel-cont">
              <p className="fel-car-title">
                Satyam’s extraordinary day at a food stall
              </p>
              <p className="fel-car-txt">
                Satyam was personally invested in the learning outcomes of his
                Students and took it upon himself to simplify even the toughest
                of concepts, and to his delight- they responded! So when the day
                of an exam came, and he learnt that one of his brightest
                Students would be unable to give the test because he had to
                stand in for an ill father at their street food stall- Satyam
                spent a day running it, which ended up defining his journey.
              </p>
              <div className="text-right text-lg-left w-100">
                <a
                  className="butn butn-main modelButn butn-spl"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://thelogicalindian.com/my-story/father-stall-teacher/"
                >
                  Read more
                </a>
              </div>
            </div>
            <div className="fel-img-cont">
              <img src={soc5} className="w-100" />
            </div>
          </div>
        </Slider>
        <div className="laf-gal-indi car-indi d-flex d-lg-none my-4">
          <div className="left" onClick={socPrevious}>
            <img src={left} />
          </div>
          <div className="right" onClick={socNext}>
            <img src={right} />
          </div>
        </div>
      </Container>

      <Container className="py-5 my-lg-5">
        <p
          className="ed-title text-center text-lg-left"
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          Systems and structures of support
        </p>
        <p
          className="jtf-head text-center text-lg-left max-75 max-sm-100 mb-5 mb-lg-3 fs-sm-22"
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          How does Teach For India empower its Fellows?
        </p>
        <p
          className="gentxt max-50 max-sm-100 mb-lg-5 "
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          From the moment Fellows enter Institute, to the moment they are
          inducted as Alumni of the movement, we offer support in various
          capacities. We also enable an ecosystem where post the Fellowship,
          they can stay connected and leverage the power of the collective.
        </p>
        <Row className="py-lg-5">
          <div className="col-lg-3">
            <img
              src={logo1}
              className="w-100 max-sm-60 mx-auto d-block my-4 my-lg-0"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
            />
          </div>
          <div className="col-lg-8 pl-lg-4 pr-lg-5 pt-3 py-lg-0 ">
            <p
              className="gentxt pr-lg-5 mb-lg-4"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              Teach For India Connect is a digital platform that connects our
              growing community of Alumni, Fellows, and Staff in a common
              virtual space. A network that enables these stakeholders to
              collaborate and share resources and opportunities on a real-time
              basis. Teach For India Connect serves to be a high-quality social
              network to engage in discussions, explore and connect with Alumni,
              hire talent, apply for jobs, and join events. Our efforts
              continue to help evolve it into a space for connections with a
              purpose, a knowledge hub created with and by the most promising
              change-makers and experts in and beyond the Teach For India
              community.
            </p>
            <div
              className="text-center text-lg-left"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
            ></div>
          </div>
        </Row>
        <Row className="py-lg-5">
          <div className="col-lg-3 max-sm-60 mx-auto mx-md-0 d-block my-4 my-lg-0">
            <img
              src={logo2}
              className="w-100"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
            />
          </div>
          <div className="col-lg-8 pl-lg-4 pr-lg-5 pt-3 pb-5 py-lg-0">
            <p
              className="gentxt pr-lg-5 mb-lg-4"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              External Career Fair is a platform for Fellows to secure
              opportunities in the development sector by matching their
              interests, skills, and aspirations to the needs of employers. The
              Career Fair is the largest structure by which graduating Fellows
              secure employment opportunities after the Fellowship. Over 70% of
              employers who took a survey after the hiring process indicated
              satisfaction along with a keen interest in hiring from our
              graduating cohort in the year to come.
            </p>
          </div>
        </Row>
      </Container>

      <Container>
        <div className="text-center">
          <p
            className="ed-title d-none d-lg-block"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            Post - Fellowship Opportunities
          </p>
          <p
            className="jtf-head d-none d-lg-block"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            What can you expect?
          </p>
          <p
            className="ed-title d-block d-lg-none"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            The Alumni
          </p>
          <p
            className="jtf-head d-block d-lg-none"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            Committed to Equity, Fuelled by Belief.
          </p>
          <p
            className="fi-txt mt-lg-5 px-lg-5 text-left text-lg-center"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            Teach For India Alumni are setting up and leading non-profit
            organisations and social enterprises that fuel change for India’s
            children in classrooms, schools, and beyond. They occupy positions
            of leadership in both the public and private sector and have founded
            over 150 mission-driven organisations, many of whom have been
            selected for support by some of the leading start-up incubators in
            India, including Villgro, Central Square Foundation, N/core, NSRCEL
            at IIM Bangalore and CIIE at IIM Ahmedabad.
          </p>

          <p
            className="my-5"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <Link to="/life-as-alumni" className="butn butn-main">
              Know More
            </Link>
          </p>
        </div>
      </Container>
    </Layout>
  )
}

export default LifeAsAFellow
